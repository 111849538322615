<template>
  <div class="common-upload-container flex justify-center items-center">
    <div class="upload-position-container">
      <div class="upload-box upload-single flex justify-center items-center" v-authenticate:OBJECT_ERASER>
        <commonUpload :fileList="fileList" :setFileList="setFileList" placeHolder="Upload an image to start"
          :multiple="false" :customRequest="customRequest" accept=".JPG,.jpg,.png,.PNG,.webp,.WEBP,.jpeg,.JPEG"
          :limitNum="10" :iconUrl="iconUploadSingle" :iconStyle="{
            width: '46px',
            height: '58px',
            'margin-bottom': '25px',
          }" />
      </div>
      <div class="image-box-background" @mouseenter="handleShowImageList" @mouseleave="handleHiddenImageList">
        <div class="inner-image-box">
          <p class="tooltip-title">No image? Try one of these</p>
          <div class="example-images-box">
            <div class="example-image-icon-box" v-for="item in imagesList" :key="item.id"
              v-authenticate:OBJECT_ERASER="{ ok: () => handleSelectImage(item) }">
              <div class="mask-item"></div>
              <img :src="item.iconSrc" class="example-image-icon" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, defineEmits, onMounted, onUnmounted } from "vue";
import commonUpload from "@/components/custom-upload/index.vue";
import { uploadImageSingle } from "@/api/upload/upload.js";
import { getFileFromUrl } from "@/utils/index.js";
import { storeToRefs } from 'pinia'
import { MessageLoading, destroy, MessageSuccess, MessageError } from "@/components/boolv-ui/Message/index.js";
import iconUploadSingle from "@/assets/upload-images/icon_upload_single.svg";
import eraserSrc1 from "./img/eraser1.webp";
import eraserSrc2 from "./img/eraser2.webp";
import eraserSrc3 from "./img/eraser3.webp";
import showNoCreditModal from '@/components/boolv-ui/custom-modal/no-credit.js'
import { checkResource } from '@/api/userInfo/index.js'
import { useViewStore } from '@/store/index.js'
const viewStore = useViewStore()

const uploadFileList = ref([]);
const isShowImageList = ref(false);
const imagesList = ref([
  {
    id: 1,
    iconSrc: eraserSrc1,
    src: eraserSrc1,
  },
  {
    id: 2,
    iconSrc: eraserSrc2,
    src: eraserSrc2,
  },
  {
    id: 3,
    iconSrc: eraserSrc3,
    src: eraserSrc3,
  },
]);
const preload = () => {
  imagesList.value.forEach(item => {
    const img = new Image();
    img.src = item.iconSrc;
  })
}
onMounted(() => {
  // viewStore.setMinViewWidth('1400px')
  preload();
})
const emits = defineEmits(["uploadComplete", "enterLoading"]);

// const checkCurrentResource = () => {

//   return new Promise((resolve, reject) => {
//     checkResource({
//       "aiType": "ERASE_PEN",
//       "deductOperType": "ONCE"
//     }).then(res => {
//       const { code, data } = res
//       const { cause, enough } = data

//       if (enough) {
//         resolve()
//       }

//       if (code !== 0 || !data || !enough) {
//         reject()
//       }

//       if (!enough && cause === 'NOT_ENOUGH') {
//         showNoCreditModal({
//           title: "Insufficient credit",
//           content: "Looks like you don't have enough credits to use this service. Upgrade to get more credits. ",
//           okText: "Upgrade now",
//         })
//       } else if (!enough && cause === 'REMAIN_IS_ZERO') {
//         showNoCreditModal({
//           title: "Insufficient credit",
//           content: "Looks like you don't have enough credits to use this service. Upgrade to get more credits. ",
//           okText: "Upgrade now"
//         })
//       }

//     }).catch(res => {
//       MessageError(res.msg)
//       reject()
//     })
//   })
// }


const customRequest = async (file) => {
  destroy()
  MessageLoading("Uploading, it will take a while");
  // await checkCurrentResource()
  const originUrl = URL.createObjectURL(file);
  // await checkCurrentResource()
  try {
    emits("enterLoading", true);
    const { code, data } = await uploadImageSingle({ file: file });
    if (code === 0) {
      emits("uploadComplete", { originUrl: originUrl, s3Uri: data.url });
      destroy()
      emits("enterLoading", false);
    } else {
      destroy()
    }
  } catch (error) {
    destroy()
    console.log("[ error ] >", error);
  }
};

const setFileList = async (fileList = []) => {
  const fileArray = fileList.map((file) => file.originFileObj);
  uploadFileList.value = fileArray;
};

const handleShowImageList = () => {
  isShowImageList.value = true;
};

const handleHiddenImageList = () => {
  isShowImageList.value = false;
};

const handleSelectImage = async (item) => {
  const file = await getFileFromUrl(item.src);
  sessionStorage.setItem("sourceImageId", JSON.stringify([`default_image_${item.id}`]));
  customRequest(file);
};



onUnmounted(() => {
  destroy()
})
</script>

<style lang="less" scoped>
.common-upload-container {
  min-height: calc(100vh - 60px);
  padding: 60px 0;
  min-width: 1400px;

  .tooltip-card {
    width: 394px;

    left: "280px";
  }

  :global(.ant-tooltip-content) {
    width: 394px;
    height: 100%;
  }

  :global(.ant-tooltip-inner) {
    width: 394px;
    height: 100%;
    color: #8f959e;
  }
}

.upload-box {
  width: 588px;
  height: 196px;
  background: #ffffff;
  border: 1px dashed #d0d0d0;
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    background-color: #f8f5ff;
  }
}

.upload-single {
  margin-right: 34px;
}

.example-images-container {
  width: 394px;

  background-color: green;
}

.example-image-icon {
  width: 110px;
  height: 110px;
  border-radius: 4px;
  object-fit: cover;
}

.mask-item {
  position: absolute;
  width: 110px;
  height: 110px;
  border-radius: 4px;
  opacity: 0;
  background-color: #000;
  transition: all .3s;
  cursor: pointer;

  &:hover {
    opacity: 0.3;
  }
}

.example-images-box {
  display: flex;
  justify-content: space-between;
  padding: 22px 20px;
}

.image-box-background {
  width: 588px;
  height: 230px;
  padding-top: 14px;
}

.inner-image-box {
  width: 588px;
  padding: 0 95px;
}

.upload-position-container {
  position: relative;
}

.example-image-icon-box {
  cursor: pointer;
  position: relative;
  background-color: #F6F6F6;
  width: 110px;
  height: 110px;
  border-radius: 4px
}
</style>
