<template>
  <div class="relative">
    <!-- 消除笔 -->
    <object-eraser v-if="uploadComplete" :imageObj="uploadImageInfo" @back="handleBack" />
    <image-upload @uploadComplete="handleUploadComplete" @enterLoading="setLoading" v-else />
  </div>
</template>
<script setup>
import ImageUpload from "./image-upload.vue";
import ObjectEraser from "@/components/models/object-eraser/index.vue";
import { ref } from "vue";
import { eventTracking } from "@/utils/eventTracking.js";

const uploadComplete = ref(false);
const uploadImageInfo = ref({});
const loading = ref(false);

const handleUploadComplete = (imageObj) => {
  eventTracking("booltool_page_upload", { tool_name: "eraser", is_batch: false })
  uploadComplete.value = true;
  uploadImageInfo.value = imageObj;
};

const handleBack = () => {
  uploadImageInfo.value = {};
  uploadComplete.value = false;
};
const setLoading = (value) => {
  console.log("[ value ] >", value);
  loading.value = value;
};
</script>

<style lang="less">

</style>
